@import "tailwindcss/index.css";
@plugin "@tailwindcss/typography";
@plugin "daisyui" {
  themes:
    ehlight --default,
    ehdark;
  root: ":root";
  logs: true;
}
@plugin "daisyui/theme" {
  name: "ehlight";
  default: true;
  prefersdark: true;
  color-scheme: "light";
  --color-base-100: oklch(100% 0 0);
  --color-base-200: oklch(100% 0 0);
  --color-base-300: oklch(95% 0 0);
  --color-base-content: oklch(21% 0.006 285.885);
  --color-primary: oklch(54.95% 0.25 274.41);
  --color-primary-content: oklch(93% 0.034 272.788);
  --color-secondary: oklch(76.49% 0.1646 63.43);
  --color-secondary-content: oklch(21% 0.006 285.885);
  --color-accent: oklch(93.15% 0.1538 126.37);
  --color-accent-content: oklch(40% 0.101 131.063);
  --color-neutral: oklch(96.34% 0.018 246.04);
  --color-neutral-content: oklch(21% 0.006 285.885);
  --color-info: oklch(55% 0.25 232.661);
  --color-info-content: oklch(93% 0.034 232.661);
  --color-success: oklch(76% 0.177 163.223);
  --color-success-content: oklch(21% 0.006 163.223);
  --color-warning: oklch(76.49% 0.165 84.429);
  --color-warning-content: oklch(21% 0.006 84.429);
  --color-error: oklch(71% 0.194 13.428);
  --color-error-content: oklch(93% 0.034 13.428);
  --radius-selector: 2rem;
  --radius-field: 2rem;
  --radius-box: 2rem;
  --size-selector: 0.25rem;
  --size-field: 0.25rem;
  --border: 1px;
  --depth: 0;
  --noise: 0;

  // --animation-btn: "0.25s"; // duration of animation when you click on button
  // --animation-input: "0.2s"; // duration of animation for inputs like checkbox, toggle, radio, etc
  // --btn-text-case: "normal"; // set default text transform for buttons
  // --btn-focus-scale: "0.95"; // scale transform of button when you focus on it
  // --card-p: "2rem"; /* padding of card */
}

@plugin "daisyui/theme" {
  name: "ehdark";
  default: false;
  prefersdark: false;
  color-scheme: "dark";
  --color-base-100: oklch(25.33% 0.016 252.42);
  --color-base-200: oklch(23.26% 0.014 253.1);
  --color-base-300: oklch(22.15% 0.012 254.09);
  --color-base-content: oklch(97% 0.013 236.62);
  --color-primary: oklch(54.95% 0.25 274.41);
  --color-primary-content: oklch(93% 0.034 272.788);
  // /* --color-primary-content: oklch(21.15% 0.012 254.09); */
  --color-secondary: oklch(76.49% 0.165 63.43);
  --color-secondary-content: oklch(21% 0.006 285.885);
  --color-accent: oklch(93.15% 0.154 126.37);
  --color-accent-content: oklch(40% 0.101 131.063);
  --color-neutral: oklch(9.81% 0.06 273.07);
  --color-neutral-content: oklch(97% 0.013 236.62);
  --color-info: oklch(74% 0.16 232.661);
  --color-info-content: oklch(29% 0.066 243.157);
  --color-success: oklch(76% 0.177 163.223);
  --color-success-content: oklch(21% 0.006 163.223);
  --color-warning: oklch(76.49% 0.165 84.429);
  --color-warning-content: oklch(21% 0.006 84.429);
  --color-error: oklch(71% 0.194 13.428);
  --color-error-content: oklch(93% 0.034 13.428);
  --radius-selector: 2rem;
  --radius-field: 2rem;
  --radius-box: 2rem;
  --size-selector: 0.25rem;
  --size-field: 0.25rem;
  --border: 1px;
  --depth: 0;
  --noise: 0;
}

@theme {
  --font-sans: Inter, sans-serif;

  --color-agency: oklch(54.95% 0.25 274.41);
  --color-agency-content: oklch(93% 0.034 272.788);
  --color-agent: oklch(76.49% 0.1646 63.43);
  --color-agent-content: oklch(21% 0.006 285.885);
  --color-consumer: oklch(69.42% 0.1635 279.71);
  --color-consumer-content: oklch(94.34% 0.02 275.9);
  --color-employee: oklch(41.07% 0.120258 147.5599);
  --color-employee-content: oklch(97% 0.03 148.53);
  --color-leadseller: oklch(93.15% 0.1538 126.37);
  --color-leadseller-content: oklch(40% 0.101 131.063);
  --color-publisher: oklch(60.17% 0.2151 356.34);
  --color-publisher-content: oklch(97% 0.02 334.72);

  --color-data-viz-1: oklch(49.51% 0.2808 269.92); /* #3333FD blue */
  --color-data-viz-2: oklch(54.97% 0.2505 274.4); /* 5050FF */
  --color-data-viz-3: oklch(61.84% 0.2081 276.92); /* 6B70FF */
  --color-data-viz-4: oklch(69.42% 0.1635 279.71); /* 8A8EFF */
  --color-data-viz-5: oklch(76.49% 0.1646 63.43); /* FA9823 orange */
  --color-data-viz-6: oklch(86.91% 0.1439 85.14); /* FFCC57 */
  --color-data-viz-7: oklch(90.45% 0.17 97.3); /* FFDF3E */
  --color-data-viz-8: oklch(41.07% 0.120258 147.5599); /* 005B1F green */
  --color-data-viz-9: oklch(55.48% 0.1651 146.81); /* 028B30 */
  --color-data-viz-10: oklch(64.5% 0.1834 147.82); /* 11AA45 */
  --color-data-viz-11: oklch(68.09% 0.1844 148.64); /* 20B652 */
  --color-data-viz-12: oklch(72.26% 0.1846 149.81); /* 2EC462 */
  --color-data-viz-13: oklch(79.1% 0.1847 150.9); /* 47DB7A */
  --color-data-viz-14: oklch(60.17% 0.2151 356.34); /* DB3084 purple */
  --color-data-viz-15: oklch(65.57% 0.2066 354.19); /* EA4B99 */
  --color-data-viz-16: oklch(76.27% 0.1664 351.42); /* FF81BE */
}

/*
  The default border color has changed to `currentColor` in Tailwind CSS v4,
  so we've added these compatibility styles to make sure everything still
  looks the same as it did with Tailwind CSS v3.

  If we ever want to remove these styles, we need to add an explicit border
  color utility to any element that depends on these defaults.
*/
@layer base {
  *,
  ::after,
  ::before,
  ::backdrop,
  ::file-selector-button {
    border-color: var(--color-base-300);
  }
}

html {
  background-color: var(--color-base-200);
}

@font-face {
  font-family: "Fellix";
  src: url("./assets/fonts/Fellix/Fellix-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter/Inter-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fellix";
}

h1 {
  font-size: 4em;
  line-height: 1.2em;
}
h2 {
  font-size: 2em;
  padding-bottom: 0.25em;
}
h3 {
  font-size: 1.5em;
}
h4 {
  font-size: 1em;
}
h5 {
  font-size: 0.8em;
}
h6 {
  font-size: 0.7em;
}

b {
  font-family: "Fellix";
}

/***** CARD *****/
.card {
  @apply rounded-box;
  @apply border-2 border-solid;
  @apply bg-base-100;

  .card-title {
    font-family: "Fellix";
  }

  &.card-primary {
    @apply bg-primary text-white border-primary;
  }

  &.card-button {
    @apply hover:scale-105 transition-all cursor-pointer;
  }
}

/***** COLLAPSE *****/
.collapse {
  @apply bg-base-100 rounded-box;
  @apply border-2 border-solid;
}

/***** STATS *****/
.stats {
  @apply rounded-box;
  @apply border-2 border-solid bg-base-100
}

input {
  &:disabled {
    @apply border-2 border-solid border-base-300;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3em;
    line-height: 1em;
  }
}

.text-2xs {
  font-size: 0.6em;
}

.btn.h-auto {
  min-height: var(--size) !important;
}

.badge.h-auto {
  min-height: var(--size) !important;
}
